<template>
  <div class="login-form">
    <form class="form" @submit.prevent="submit">
      <div class="pb-13 pt-lg-0 pt-5">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          Recuperar senha
        </h3>
        <span class="text-muted font-weight-bold font-size-h4">
          Olá, {{ user.nome }}. Insira a sua nova senha.
        </span>
      </div>
      <div class="form-group">
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          type="email"
          :value="user.email"
          disabled=""
        />
      </div>
      <div class="form-group">
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          type="password"
          placeholder="Senha"
          v-model="form.password"
          autocomplete="off"
          required
        />
      </div>
      <div class="form-group">
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          type="password"
          placeholder="Repita a senha"
          v-model="form.password_confirm"
          autocomplete="off"
          required
        />
      </div>
      <div class="pb-lg-0 pb-5">
        <button
          type="submit"
          class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
        >
          Salvar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import MsgService from "@/core/services/msg.service";

export default {
  name: "RecoverConfirm",
  data() {
    return {
      loading: false,
      user: {
        nome: null,
        email: null
      },
      form: {
        password: null,
        password_confirm: null
      }
    };
  },
  props: ["hash"],
  methods: {
    get() {
      // send login request
      ApiService.get("auth/recover/" + this.hash)
        .then(({ data }) => {
          this.user = data.response;
        })
        .catch(({ response }) => {
          this.$router.push({ name: "login" });
          MsgService.show(response.data.message);
        });
    },
    submit() {
      if (!this.loading) {
        // set spinner to submit button
        this.loading = true;

        // send login request
        ApiService.post("auth/recover/" + this.hash, this.form)
          .then(({ data }) => {
            this.$router.push({ name: "login" });
            MsgService.show(data.message);
            this.loading = true;
          })
          .catch(({ response }) => {
            MsgService.show(response.data.message);
            this.loading = true;
          });
      }
    }
  },
  created() {
    this.get();
  }
};
</script>
